/* eslint no-console:0 */
// This file is automatically compiled by Vite, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import "regenerator-runtime/runtime";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import "../../stylesheets/welcome.scss";

import { initializeFlashMessages } from "../utils/toasts";

import "./../initializers/init_jquery";
import "./../initializers/init_jquery_plugins";

import "@hotwired/turbo-rails";
import moment from "../initializers/moment";
import { showCookiesConsent } from "../initializers/cookies_directive";
function initWelcome() {
  window.moment = moment;

  window.moment.locale(gon.locale);
  // in case there is no I18n defined
  (window.I18n as any) ||= {};
  window.I18n.locale = gon.locale;
  showCookiesConsent();
  // load jquery ujs to automatically add CSRF token to XHR requests

  // load and initialize turbolinks
  void initializeFlashMessages();
}

window.SIALOGIC = {
  initWelcome,
};
